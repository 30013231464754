var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { action: "#", method: "post" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "row" }, [
        _c("div", { staticClass: "col-12 col-md-9 m-0 p-0" }, [
          _c("input", {
            directives: [
              {
                name: "validate",
                rawName: "v-validate",
                value: "required|email",
                expression: "'required|email'"
              },
              {
                name: "model",
                rawName: "v-model",
                value: _vm.email,
                expression: "email"
              }
            ],
            staticClass: "w-100 p-1",
            attrs: {
              type: "email",
              name: "email",
              autocomplete: "email",
              placeholder: _vm.$t("editShort.email"),
              "data-vv-as": String(_vm.$t("editShort.email")).toLowerCase()
            },
            domProps: { value: _vm.email },
            on: {
              input: function($event) {
                if ($event.target.composing) {
                  return
                }
                _vm.email = $event.target.value
              }
            }
          })
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "col mt-2 m-md-0 p-0 text-center text-md-left" },
          [
            _c(
              "button",
              {
                staticClass: "btn btn-sqr-red text-uppercase",
                class: _vm.disableButton ? "disabled" : "",
                attrs: { disabled: _vm.disableButton }
              },
              [
                _vm._v(
                  "\n              " +
                    _vm._s(_vm.$t("editShort.sendLink")) +
                    "\n            "
                ),
                _vm.submitting === true
                  ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                  : _vm._e()
              ]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.successMessage.length > 0
          ? _c("div", { staticClass: "col-12 col-md-9 mt-2 px-0" }, [
              _vm.successMessage.length > 0
                ? _c(
                    "div",
                    { staticClass: "alert alert-success animated fadeInDown" },
                    [_c("span", [_vm._v(_vm._s(_vm.successMessage))])]
                  )
                : _vm._e()
            ])
          : _vm._e()
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "row" }, [
        _vm.errorMessages.length > 0
          ? _c("div", { staticClass: "col-12 col-md-9 mt-2 px-0" }, [
              _c(
                "div",
                { staticClass: "alert alert-danger animated fadeInDown" },
                [
                  _c(
                    "ul",
                    { staticClass: "mt-0" },
                    _vm._l(_vm.errorMessages, function(message) {
                      return _c(
                        "li",
                        {
                          key: message,
                          staticClass: "w-100 text-left font-weight-normal"
                        },
                        [
                          _vm._v(
                            "\n            " + _vm._s(message) + "\n          "
                          )
                        ]
                      )
                    }),
                    0
                  )
                ]
              )
            ])
          : _vm._e(),
        _vm._v(" "),
        _vm.errors.first("email")
          ? _c("div", { staticClass: "col-12 col-md-9 mt-2 px-0" }, [
              _c(
                "div",
                { staticClass: "alert alert-danger animated fadeInDown" },
                [
                  _c("div", { staticClass: "text-red" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.errors.first("email")) +
                        "\n        "
                    )
                  ])
                ]
              )
            ])
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }