var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "row justify-content-center" }, [
      _c("div", { staticClass: "col-md-4" }, [
        _c(
          "ul",
          _vm._l(_vm.passRequirements, function(requirement, index) {
            return _c("li", { key: index, staticClass: "text-white" }, [
              _vm._v("\n          " + _vm._s(requirement) + "\n        ")
            ])
          }),
          0
        )
      ])
    ]),
    _vm._v(" "),
    _c(
      "form",
      {
        attrs: { action: "#", method: "post", id: "createAccountForm" },
        on: {
          submit: function($event) {
            $event.preventDefault()
            return _vm.submit($event)
          }
        }
      },
      [
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "single-input-item" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.newPassword,
                    expression: "newPassword"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|min:8|upperCase|lowerCase|number",
                    expression: "'required|min:8|upperCase|lowerCase|number'"
                  }
                ],
                ref: "newPassword",
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("editShort.newPassword"),
                  name: "newPassword",
                  "data-vv-as": "New Password"
                },
                domProps: { value: _vm.newPassword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.newPassword = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-red" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.errors.first("newPassword")) +
                    "\n          "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "col-md-6" }, [
            _c("div", { staticClass: "single-input-item" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.confirmPassword,
                    expression: "confirmPassword"
                  },
                  {
                    name: "validate",
                    rawName: "v-validate",
                    value: "required|confirmed:newPassword",
                    expression: "'required|confirmed:newPassword'"
                  }
                ],
                attrs: {
                  type: "password",
                  placeholder: _vm.$t("editShort.confirmPassword"),
                  name: "confirmPassword",
                  "data-vv-as": "Confirm Password"
                },
                domProps: { value: _vm.confirmPassword },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.confirmPassword = $event.target.value
                  }
                }
              }),
              _vm._v(" "),
              _c("div", { staticClass: "text-red" }, [
                _vm._v(
                  "\n            " +
                    _vm._s(_vm.errors.first("confirmPassword")) +
                    "\n          "
                )
              ])
            ])
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "row justify-content-center" }, [
          _c("div", { staticClass: "single-input-item" }, [
            !_vm.changesSaved && !_vm.submitting
              ? _c(
                  "button",
                  {
                    staticClass: "btn btn-sqr-red",
                    attrs: { disabled: !_vm.validToken }
                  },
                  [_vm._v(_vm._s(_vm.$t("editShort.submit")))]
                )
              : _vm._e(),
            _vm._v(" "),
            !_vm.changesSaved && _vm.submitting
              ? _c("div", { staticClass: "btn btn-sqr-red" }, [
                  _c("i", {
                    staticClass: "fa fa-spin fa-spinner",
                    attrs: { "aria-hidden": "true" }
                  })
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.changesSaved
              ? _c("div", { staticClass: "btn bg-green text-white" }, [
                  _c("span", [
                    _vm._v(_vm._s(_vm.$t("editShort.loggingOut"))),
                    _c("i", {
                      staticClass: "fa fa-spin fa-spinner",
                      attrs: { "aria-hidden": "true" }
                    })
                  ])
                ])
              : _vm._e()
          ])
        ]),
        _vm._v(" "),
        _vm.errorMessages.length > 0
          ? _c(
              "div",
              { staticClass: "alert alert-danger animated fadeInDown mt-3" },
              [
                _c(
                  "ul",
                  { staticClass: "mt-0" },
                  _vm._l(_vm.errorMessages, function(message) {
                    return _c(
                      "li",
                      {
                        key: message,
                        staticClass: "w-100 text-left font-weight-normal"
                      },
                      [_vm._v("\n          " + _vm._s(message) + "\n        ")]
                    )
                  }),
                  0
                )
              ]
            )
          : _vm._e()
      ]
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }