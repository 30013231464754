var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", { staticClass: "language text-white" }, [
    _vm._v("\n  " + _vm._s(_vm.selectedLanguage) + "\n  "),
    _c("i", { staticClass: "fa fa-angle-down" }),
    _vm._v(" "),
    _c(
      "ul",
      { staticClass: "dropdown-list" },
      _vm._l(this.languages, function(language) {
        return _c("li", { key: language.index }, [
          language.index !== _vm.languageIndex
            ? _c(
                "a",
                { attrs: { href: language.href ? language.href : "#" } },
                [_vm._v(_vm._s(language[_vm.languageIndex]))]
              )
            : _c("span", [_vm._v(_vm._s(language[_vm.languageIndex]))])
        ])
      }),
      0
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }