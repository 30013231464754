<template>
  <div>
    <div class="row justify-content-center">
      <div class="col-md-4">
        <ul>
          <li class="text-white"
            v-for="(requirement, index) in passRequirements" :key="index">
            {{ requirement }}
          </li>
        </ul>
      </div>
    </div>

    <form action="#" method="post" id="createAccountForm" @submit.prevent="submit">
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="single-input-item">
            <input type="password"
              :placeholder="$t('editShort.newPassword')"
              name="newPassword"
              v-model="newPassword"
              v-validate="'required|min:8|upperCase|lowerCase|number'"
              data-vv-as="New Password"
              ref="newPassword"
            >
            <div class="text-red">
              {{ errors.first('newPassword') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="col-md-6">
          <div class="single-input-item">
            <input type="password"
              :placeholder="$t('editShort.confirmPassword')"
              name="confirmPassword"
              v-model="confirmPassword"
              v-validate="'required|confirmed:newPassword'"
              data-vv-as="Confirm Password"
            >
            <div class="text-red">
              {{ errors.first('confirmPassword') }}
            </div>
          </div>
        </div>
      </div>
      <div class="row justify-content-center">
        <div class="single-input-item">
          <button class="btn btn-sqr-red" v-if="!changesSaved && !submitting" :disabled="!validToken">{{ $t('editShort.submit') }}</button>
          <div class="btn btn-sqr-red" v-if="!changesSaved && submitting">
            <i class="fa fa-spin fa-spinner" aria-hidden="true"></i></div>
          <div class="btn bg-green text-white" v-if="changesSaved">
            <span>{{ $t('editShort.loggingOut') }}<i class="fa fa-spin fa-spinner" aria-hidden="true"></i></span>
          </div>
        </div>
      </div>
      <div v-if="errorMessages.length > 0" class="alert alert-danger animated fadeInDown mt-3">
        <ul class="mt-0">
          <li class="w-100 text-left font-weight-normal" v-for="message in errorMessages" :key="message">
            {{ message }}
          </li>
        </ul>
      </div>
    </form>
  </div>
</template>

<script>

import { update } from './Account';

export default {
  name: "resetPassword",
  data: () => {
    return {
      passRequirements: [],
      newPassword: '',
      confirmPassword: '',
      submitting: false,
      changesSaved: false,
      errorMessages: [],
      account,
      validToken: true
    }
  },
  created: function() {
    if (!this.account) {
      this.validToken = false;
      this.errorMessages.push(this.$t('editShort.invalid_token'));
      return;
    }
    this.passRequirements = [
      this.$t('editShort.pass_length'),
      this.$t('editShort.pass_uppercase'),
      this.$t('editShort.pass_lowercase')
    ];
    this.$validator.extend('upperCase', {
      getMessage: () => this.$t('editShort.field_upperCase'),
      validate: value => value.match(/[A-Z]/g) !== null
    });
    this.$validator.extend('lowerCase', {
      getMessage: () => this.$t('editShort.field_lowerCase'),
      validate: value => value.match(/[a-z]/g) !== null
    });
    this.$validator.extend('number', {
      getMessage: () => this.$t('editShort.field_number'),
      validate: value => value.match(/[0-9]/g) !== null
    })
  },
  methods: {
    async submit() {
      this.submitting = true;
      let response;
      try {
        const isFormValid = await this.$validator.validateAll();
        if (isFormValid) {
          this.account['password'] = this.newPassword;
          response = await update(this.account);
          if ([204, 205].includes(response)) {
            this.changesSaved = true;
          } else {
            this.resetPasswordFields();
            this.errorMessages.push(this.$t('editShort.error_message'));
          }
        }
      } catch (error) {
        this.errorMessages.push(this.$t('editShort.error_message'));
      } finally {
        this.submitting = false;
        if ([204, 205].includes(response)) {
          window.location.href = '/';
        }
      }
    },

    resetPasswordFields() {
      this.newPassword = '';
      this.confirmPassword = '';
    }

  }
}
</script>

<style scoped>
li {
  list-style: initial;
}
</style>
