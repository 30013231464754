<template>
  <form action="#" method="post" @submit.prevent="validateBeforeSubmit" id="createAccountForm">
    <div class="row">
      <div class="col-md-6">
        <div class="single-input-item">
          <label for="firstName" class="required">{{ $t('editShort.firstName') }}</label>
          <input type="text" id="firstName" :placeholder="$t('editShort.firstName')" name="firstName"
            v-validate="'required'"
            v-model="form.firstName" autocomplete="given-name"
            :data-vv-as="String($t('editShort.firstName')).toLowerCase()"/>
          <div class="text-red">
            {{ errors.first('firstName') }}
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="single-input-item">
          <label for="lastName" class="required">{{ $t('editShort.lastName') }}</label>
          <input type="text" id="lastName" :placeholder="$t('editShort.lastName')" name="lastName" v-validate="'required'"
            v-model="form.lastName" autocomplete="family-name"
            :data-vv-as="String($t('editShort.lastName')).toLowerCase()"/>
          <div class="text-red">
            {{ errors.first('lastName') }}
          </div>
        </div>
      </div>
    </div>
    <div class="single-input-item">
      <label for="organization" class="required">{{ $t('editShort.organization') }}</label>
      <input type="text" id="organization" :placeholder="$t('editShort.organization')" v-validate="'required'"
        v-model="form.organization" name="organization" autocomplete="organization"
        :data-vv-as="String($t('editShort.organization')).toLowerCase()"/>
      <div class="text-red">
        {{ errors.first('organization') }}
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="single-input-item">
          <label for="email" class="required">{{ $t('editShort.email') }}</label>
          <input type="email" id="email" :placeholder="$t('editShort.email')" v-validate="'required'"
            v-model="form.email"
            autocomplete="email" name="email" :data-vv-as="String($t('editShort.email')).toLowerCase()"/>
          <div class="text-red">
            {{ errors.first('email') }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="single-input-item">
          <label for="phone" class="required">{{ $t('editShort.phone') }}</label>
          <input type="text" id="phone" :placeholder="$t('editShort.phone')" v-validate="'required'"
            v-model="form.phone"
            autocomplete="phone" name="phone" :data-vv-as="String($t('editShort.phone')).toLowerCase()"/>
          <div class="text-red">
            {{ errors.first('phone') }}
          </div>
        </div>
      </div>
    </div>

    <div class="single-input-item">
      <label for="message">{{ $t('editShort.message') }}</label>
      <textarea id="message" name="message" v-model="form.message" :placeholder="$t('editShort.message')"></textarea>
      <div class="text-red">
        {{ errors.first('message') }}
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-12">
        <small>
          <h6>{{ $t('editShort.password_contain') }}</h6>
          <ul>
            <li v-for="requirement in passwordRequirements" :key="requirement">
              {{ requirement }}
            </li>
          </ul>
        </small>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-6">
        <div class="single-input-item">
          <!--TODO: in updateAccount.vue, add currentPassword and replace label below with newPassword.-->
          <label for="password" class="required">{{ $t('editShort.password') }}</label>
          <input type="password" id="password"
            :placeholder="$t('editShort.password')"
            name="password"
            v-model="form.password"
            v-validate="'required|min:8|upperCase|lowerCase|number'"
            :data-vv-as="String($t('editShort.password')).toLowerCase()"
            ref="password">
          <div class="text-red">
            {{ errors.first('password') }}
          </div>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="single-input-item">
          <label for="passwordConfirmation" class="required">{{ $t('editShort.passwordConfirmation') }}</label>
          <input type="password" id="passwordConfirmation" :placeholder="$t('editShort.passwordConfirmation')"
            v-model="form.passwordConfirmation" v-validate="'required|confirmed:password'"
            name="passwordConfirmation" :data-vv-as="String($t('editShort.passwordConfirmation')).toLowerCase()"/>
          <div class="text-red">
            {{ errors.first('passwordConfirmation') }}
          </div>
        </div>
      </div>
    </div>

    <div v-if="successMessage.length > 0" class="alert alert-success animated fadeInDown mb-3">
      <h4 class="text-center pb-4">{{ $t('editShort.thankYou') }}</h4>
      <span>{{ successMessage }}</span>
    </div>
    <div v-if="errorMessages.length > 0" class="alert alert-danger animated fadeInDown mb-3">
      <ul v-if="errorMessages.length > 1" class="mt-0">
        <li class="w-100 text-left font-weight-normal" v-for="message in errorMessages">
          {{ message }}
        </li>
      </ul>
      <span v-else>{{ errorMessages[0] }}</span>
    </div>
    <div class="checkout-box-wrap">
      <div class="single-input-item">
        <div class="custom-control custom-checkbox">
          <input type="checkbox" class="custom-control-input" name="agreesToTerms" id="agreesToTerms"
            v-model="form.agreesToTerms" v-validate="'required'">
          <label class="custom-control-label" for="agreesToTerms">{{ $t('editShort.agreesToTerms') }}</label>
        </div>
        <div class="text-red">
          {{ errors.first('agreesToTerms') }}
        </div>
      </div>
    </div>
    <div class="single-input-item">
      <!--TODO: use in updateAccount.vue
      <button class="btn btn-sqr">{{ $t('editShort.saveChanges') }}</button>-->
      <button class="btn btn-sqr-red">{{ $t('editShort.submit') }}</button>
    </div>
  </form>
</template>

<script>
import { create as create } from './Account';

export default {
  name: "createAccount",
  data: () => {
    return {
      form: {
        firstName: '',
        lastName: '',
        organization: '',
        email: '',
        phone: '',
        message: '',
        newPassword: '',
        confirmPassword: '',
        agreesToTerms: false,
      },
      passwordRequirements: [],
      successMessage: '',
      errorMessages: [],
      submitting: false,
      submitted: false,
    }
  },
  created: function() {
    this.passwordRequirements = [
      this.$t('editShort.pass_length'),
      this.$t('editShort.pass_uppercase'),
      this.$t('editShort.pass_lowercase')
    ];
    this.$validator.extend('upperCase', {
      getMessage: () => this.$t('editShort.field_upperCase'),
      validate: value => value.match(/[A-Z]/g) !== null
    });
    this.$validator.extend('lowerCase', {
      getMessage: () => this.$t('editShort.field_lowerCase'),
      validate: value => value.match(/[a-z]/g) !== null
    });
    this.$validator.extend('number', {
      getMessage: () => this.$t('editShort.field_number'),
      validate: value => value.match(/[0-9]/g) !== null
    });
  },
  methods: {
    validateBeforeSubmit() {
      //Reset error messages
      this.errorMessages = [];
      //Update UI to show this is loading
      this.submitting = true;

      this.$validator.validateAll()
        .then(result => {
          if (result === true) {
            //Clear error messages
            this.errorMessages = [];
            return create(this.form);
          } else {
            return Promise.reject();
          }
        })
        .then(response => {
          this.successMessage = this.$t('editShort.createAccountSuccess');
        })
        .catch(response => {
          if (typeof response !== 'undefined'
            && response.hasOwnProperty('data')
            && response.data.messages.length > 0) {

            //Comes from Drive? It's already an array
            this.errorMessages = response.data.messages;
          } else if (typeof response === 'string') {
            //A string? It's a single message.
            this.errorMessages.push(response)
          } else if (this.errorMessages.length === 0 && this.errors.items.length === 0) {
            //If it wasn't vee-validate that got us here, and if there's
            //nothing else, show something generic.
            console.log(response);
            this.errorMessages.push(this.$t('editShort.error_message'))
          }
        })
        .finally(() => {
          //Update data to change UI
          this.submitting = false;
          this.submitted = true;
        });
    }
  }
}
</script>
