import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

import VeeValidate from 'vee-validate';

import createAccount from './vue/account/createAccount';
import loginForm from './vue/account/loginForm';
import languageSwitcher from './vue/shop/languageSwitcher';
import languageSwitcherMobile from './vue/shop/languageSwitcherMobile';
import {
  loadContentForVue,
  getVeeValidateOptions,
} from './utilities/driveVueWrapper';

import initializeApp from './settings';
import forgotPassword from './vue/account/forgotPassword';
import resetPassword from './vue/account/resetPassword';
initializeApp();

const i18n = loadContentForVue(languageIndex);
Vue.use(VeeValidate, getVeeValidateOptions(i18n));

new Vue({
  el: '#app',
  components: {
    createAccount,
    forgotPassword,
    loginForm,
    resetPassword,
  },
  props: ['timestamp'],
  i18n,
});

window.navbarContainer = new Vue({
  el: '#navbarContainer',
  components: {
    languageSwitcher,
  },
  i18n,
});

window.navbarContainerMobile = new Vue({
  el: '#navbarContainerMobile',
  components: {
    languageSwitcherMobile,
  },
  i18n,
});
