<template>
  <form action="#" method="post" @submit.prevent="validateBeforeSubmit" id="loginForm">
    <div class="single-input-item">
      <input type="email" name="email" v-validate="'required'" v-model="email" autocomplete="email"
             :placeholder="$t('editShort.email')"
             :data-vv-as="String($t('editShort.email')).toLowerCase()"/>
      <div class="text-red">
        {{ errors.first('email') }}
      </div>
    </div>
    <div class="single-input-item">
      <input type="password" name="password" v-validate="'required'" v-model="password" autocomplete="password"
             :placeholder="$t('editShort.password')"
             :data-vv-as="String($t('editShort.password')).toLowerCase()"/>
      <div class="text-red">
        {{ errors.first('password') }}
      </div>
    </div>
    <div class="single-input-item">
      <div class="login-reg-form-meta d-flex align-items-center justify-content-between">
        <!-- TODO: uncomment to get a "stay signed in" checkbox
        <div class="remember-meta">
          <div class="custom-control custom-checkbox">
            <input type="checkbox" class="custom-control-input" id="rememberMe" v-model="rememberMe">
            <label class="custom-control-label" for="rememberMe">{{ $t('editShort.rememberMe') }}</label>
          </div>
        </div>
        -->
        <a :href="forgotPasswordLink" class="forget-pwd">{{ $t('editShort.forgetPassword') }}</a>
      </div>
    </div>
    <div class="single-input-item">
      <div v-if="isSuccess" class="alert alert-success animated fadeInDown">
        <p>
          {{ $t('editShort.loginSuccess') }}
        </p>
        <i class="fa fa-spinner fa-spin fa-2x"></i>
      </div>
      <div v-if="errorMessages.length > 0" class="alert alert-danger animated fadeInDown">
        <ul v-if="errorMessages.length > 1" class="mt-0">
          <li class="w-100 text-left font-weight-normal" v-for="message in errorMessages">
            {{ message }}
          </li>
        </ul>
        <span v-else>
          {{ errorMessages[0] }}
        </span>
      </div>
      <button v-if="!isSuccess" class="btn btn-sqr-red text-uppercase" :disabled="submitting">
        {{ $t('editShort.logIn') }}
        <i class="fa fa-spinner fa-spin" v-if="submitting"></i>
      </button>
    </div>
  </form>
</template>

<script>
import { login } from './Account';

export default {
  name: "loginForm",
  props: {
    languageIndex: { type: String, required: true },
    forgotPasswordLink: {type: String, required: true}
  },
  data: () => {
    return {
      email: '',
      password: '',
      rememberMe: false,
      errorMessages: [],
      submitting: false,
      isSuccess: false,
      validSubmission: false,
    };
  },
  methods: {
    async validateBeforeSubmit() {
      this.errorMessages = [];
      try {
        const valid = await this.$validator.validateAll();
        if (valid) {
          this.submitting = true;
          const response = await login(this.email, this.password, this.languageIndex);

          if (response.data.status === 'success') {
            this.isSuccess = true;
            window.location.href = response.data.data.dashboard.link;
          } else {
            this.errorMessages.push(response.data.message.join('<br>'));
          }
        }
      } catch (error) {
        this.errorMessages.push(this.$t('editShort.error_message'));
      } finally {
        this.submitting = false;
      }
    }
  }
}
</script>

