var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "form",
    {
      attrs: { action: "#", method: "post", id: "loginForm" },
      on: {
        submit: function($event) {
          $event.preventDefault()
          return _vm.validateBeforeSubmit($event)
        }
      }
    },
    [
      _c("div", { staticClass: "single-input-item" }, [
        _c("input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.email,
              expression: "email"
            }
          ],
          attrs: {
            type: "email",
            name: "email",
            autocomplete: "email",
            placeholder: _vm.$t("editShort.email"),
            "data-vv-as": String(_vm.$t("editShort.email")).toLowerCase()
          },
          domProps: { value: _vm.email },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.email = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "text-red" }, [
          _vm._v("\n      " + _vm._s(_vm.errors.first("email")) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "single-input-item" }, [
        _c("input", {
          directives: [
            {
              name: "validate",
              rawName: "v-validate",
              value: "required",
              expression: "'required'"
            },
            {
              name: "model",
              rawName: "v-model",
              value: _vm.password,
              expression: "password"
            }
          ],
          attrs: {
            type: "password",
            name: "password",
            autocomplete: "password",
            placeholder: _vm.$t("editShort.password"),
            "data-vv-as": String(_vm.$t("editShort.password")).toLowerCase()
          },
          domProps: { value: _vm.password },
          on: {
            input: function($event) {
              if ($event.target.composing) {
                return
              }
              _vm.password = $event.target.value
            }
          }
        }),
        _vm._v(" "),
        _c("div", { staticClass: "text-red" }, [
          _vm._v("\n      " + _vm._s(_vm.errors.first("password")) + "\n    ")
        ])
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "single-input-item" }, [
        _c(
          "div",
          {
            staticClass:
              "login-reg-form-meta d-flex align-items-center justify-content-between"
          },
          [
            _c(
              "a",
              {
                staticClass: "forget-pwd",
                attrs: { href: _vm.forgotPasswordLink }
              },
              [_vm._v(_vm._s(_vm.$t("editShort.forgetPassword")))]
            )
          ]
        )
      ]),
      _vm._v(" "),
      _c("div", { staticClass: "single-input-item" }, [
        _vm.isSuccess
          ? _c(
              "div",
              { staticClass: "alert alert-success animated fadeInDown" },
              [
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("editShort.loginSuccess")) +
                      "\n      "
                  )
                ]),
                _vm._v(" "),
                _c("i", { staticClass: "fa fa-spinner fa-spin fa-2x" })
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        _vm.errorMessages.length > 0
          ? _c(
              "div",
              { staticClass: "alert alert-danger animated fadeInDown" },
              [
                _vm.errorMessages.length > 1
                  ? _c(
                      "ul",
                      { staticClass: "mt-0" },
                      _vm._l(_vm.errorMessages, function(message) {
                        return _c(
                          "li",
                          { staticClass: "w-100 text-left font-weight-normal" },
                          [
                            _vm._v(
                              "\n          " + _vm._s(message) + "\n        "
                            )
                          ]
                        )
                      }),
                      0
                    )
                  : _c("span", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessages[0]) + "\n      "
                      )
                    ])
              ]
            )
          : _vm._e(),
        _vm._v(" "),
        !_vm.isSuccess
          ? _c(
              "button",
              {
                staticClass: "btn btn-sqr-red text-uppercase",
                attrs: { disabled: _vm.submitting }
              },
              [
                _vm._v(
                  "\n      " + _vm._s(_vm.$t("editShort.logIn")) + "\n      "
                ),
                _vm.submitting
                  ? _c("i", { staticClass: "fa fa-spinner fa-spin" })
                  : _vm._e()
              ]
            )
          : _vm._e()
      ])
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }