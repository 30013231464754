import Vue from 'vue';
import VueI18n from 'vue-i18n';
import enValidationMessages from 'vee-validate/dist/locale/en';
import frValidationMessages from 'vee-validate/dist/locale/fr';

Vue.use(VueI18n);

export function getVeeValidateOptions(i18n) {
  return {
    aria: true,
    classes: true,
    classNames: {
      invalid: 'error',
    },
    i18n,
    dictionary: {
      en: enValidationMessages,
      fr: frValidationMessages,
    },
  };
}

export function loadContentForVue(languageIndex) {
  const i18nOptions = {
    locale: languageIndex,
    messages: {
      en: {
        editShort: editShort.en,
      },
      fr: {
        editShort: editShort.fr,
      },
    },
  };

  return new VueI18n(i18nOptions);
}
