export default function initializeApp() {
  window.settings = window.settings || {
    vueI18n: undefined,
    account: undefined,
    cartSession: undefined,
    locale: 'en',
    currency: 'USD',
    loadingInterval: 150, //when you first load the content, check if it's loaded every x seconds
  };
  if (!window.localStorage.hasOwnProperty('cachedItems')) {
    window.localStorage.cachedItems = [];
  }
}
