import axios from 'axios';

export function create(data) {
  let postData = {
    firstName: data.firstName,
    lastName: data.lastName,
    organization: data.organization,
    email: data.email,
    phone: data.phone,
    message: data.message,
    password: data.password,
    passwordConfirmation: data.passwordConfirmation,
  };
  return axios.post('/api/v1/Account/register', postData).then(response => {
    if (response.data.status === 'success') {
      return Promise.resolve(response);
    } else {
      return Promise.reject(response);
    }
  });
}

export async function login(email, password, languageIndex = 'en') {
  return await axios.post(
    `/api/v1/Account/login?languageIndex=${languageIndex}`,
    null,
    {
      auth: { username: email, password: password },
      headers: { 'Content-Type': 'application/json' },
      validateStatus: function(status) {
        return (status >= 200 && status < 300) || status === 401;
      },
    },
  );
}

export async function logout() {
  const response = await axios.delete('/api/v1/Account/logout');

  removeAccountInfo(response.status);
  window.location.href = '/';
}

function removeAccountInfo(status) {
  if (status === 205) {
    window.localStorage.removeItem('currency');
    window.localStorage.removeItem('availableCurrencies');
  }
}

export function sendResetRequest(email) {
  return axios
    .post(
      '/api/v1/Account/reset',
      {
        email,
      },
      { headers: { 'Content-Type': 'application/json' } },
    )
    .then(response => {
      if (response.status === 204) {
        return Promise.resolve();
      } else {
        return Promise.reject();
      }
    })
    .catch(() => {
      return Promise.reject();
    });
}

export function update(item) {
  return axios
    .patch(
      `/api/v1/Account/${item['GUID']}`,
      { item },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
    .then(response => {
      removeAccountInfo(response.status);
      return response.status;
    })
    .catch(() => {
      return false;
    });
}

export function updatePricesViewed(storeItemID) {
  const postData = { item: { storeItemID: storeItemID } };
  return axios
    .post('/api/v1/PriceView/', postData, {
      headers: { 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.data.status === 'success') {
        return Promise.resolve(response);
      } else {
        return Promise.reject(response);
      }
    });
}
