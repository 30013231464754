var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("li", [
    _c("div", { staticClass: "dropdown mobile-top-dropdown" }, [
      _c(
        "a",
        {
          staticClass: "dropdown-toggle",
          attrs: {
            href: "#",
            id: "language",
            "data-toggle": "dropdown",
            "aria-haspopup": "true",
            "aria-expanded": "false"
          }
        },
        [
          _c("span", [_vm._v(_vm._s(_vm.selectedLanguage))]),
          _vm._v(" "),
          _c("i", { staticClass: "fa fa-angle-down" })
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "dropdown-menu",
          attrs: { "aria-labelledby": "language" }
        },
        _vm._l(_vm.languages, function(language) {
          return _c(
            "a",
            {
              key: language.index,
              staticClass: "dropdown-item",
              attrs: { href: _vm.getHref(language) }
            },
            [_vm._v(_vm._s(language[_vm.languageIndex]))]
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }