<template>
  <li class="language text-white">
    {{ selectedLanguage }}
    <i class="fa fa-angle-down"></i>

    <ul class="dropdown-list">
      <li v-for="language in this.languages" :key="language.index">
        <a v-if="language.index !== languageIndex"
           :href="language.href ? language.href : '#'">{{ language[languageIndex] }}</a>
        <span v-else>{{ language[languageIndex] }}</span>
      </li>
    </ul>

  </li>
</template>

<script>
export default {
  name: 'languageSwitcher',
  data: function () {
    return {
      languageIndex,
      languages,
    };
  },
  computed: {
    selectedLanguage() {
      return this.languages.find(language => language.index === this.languageIndex)[this.languageIndex];
    },
  },
  created: function () {
    const alternateLinks = document.querySelectorAll('[rel="alternate"]');
    alternateLinks.forEach(link => {
      this.languages.find(language => language.index === link.hreflang).href = link.href;
    });
  },
}
</script>

