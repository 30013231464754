<template>
  <form action="#" method="post" @submit.prevent="validateBeforeSubmit">
    <div class="row">
      <div class="col-12 col-md-9 m-0 p-0">
        <input type="email" name="email" v-validate="'required|email'" v-model="email" autocomplete="email"
              :placeholder="$t('editShort.email')" class="w-100 p-1"
              :data-vv-as="String($t('editShort.email')).toLowerCase()"/>
        
      </div>

      <div class="col mt-2 m-md-0 p-0 text-center text-md-left">
        <button class="btn btn-sqr-red text-uppercase" :disabled="disableButton"
              :class="disableButton ? 'disabled' : ''">
                {{ $t('editShort.sendLink') }}
              <i class="fa fa-spinner fa-spin" v-if="submitting === true"></i>
        </button>
      </div>
    </div>

    <div class="row">
      <div v-if="successMessage.length > 0" class="col-12 col-md-9 mt-2 px-0">
        <div v-if="successMessage.length > 0" class="alert alert-success animated fadeInDown">
          <span>{{ successMessage }}</span>
        </div>
      </div>
    </div>

    <div class="row">
      <div v-if="errorMessages.length > 0" class="col-12 col-md-9 mt-2 px-0">
        <div class="alert alert-danger animated fadeInDown">
          <ul class="mt-0">
            <li class="w-100 text-left font-weight-normal" v-for="message in errorMessages" :key="message">
              {{ message }}
            </li>
          </ul>
       </div>
      </div>
      <div class="col-12 col-md-9 mt-2 px-0" v-if="errors.first('email')">
        <div class="alert alert-danger animated fadeInDown">
          <div class="text-red">
            {{ errors.first('email') }}
          </div>
        </div>
      </div>
    </div>
    
  </form>
</template>

<script>
  import {sendResetRequest} from './Account';

  export default {
    name: "forgotPassword",
    data: () => {
      return {
        email: '',
        successMessage: '',
        errorMessages: [],
        submitting: false,
      };
    },
    watch:{
      email: function(){
        this.errorMessages = [];
        this.successMessage = '';
      }
    },
    computed:{
      disableButton: function(){
        return (this.submitting || this.containsErrors)
      },
      containsErrors: function(){
        return this.errorMessages.length > 0 ||
               this.errors.items.length > 0;
      }
    },
    methods: {
      async validateBeforeSubmit(){
        // Make sure fields are valid using VeeValidate
        this.submitting = true;
        try{
          const isFieldValid = await this.$validator.validateAll();
          if(isFieldValid){
              await sendResetRequest(this.email);
              this.errorMessages = [];
              this.successMessage = this.$t('editShort.resetLinkSent');
              this.submitted = true;
          }
        } catch (error) {
          this.errorMessages.push(this.$t('editShort.error_message'));
        } finally {
          this.submitting = false;
        }

      },
      
    },
  }
</script>

