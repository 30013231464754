<template>
  <li>
    <div class="dropdown mobile-top-dropdown">
      <a
        href="#"
        id="language"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        class="dropdown-toggle"
      >
        <span>{{ selectedLanguage }}</span> <i class="fa fa-angle-down"></i>
      </a>
      <div aria-labelledby="language" class="dropdown-menu">
        <a
          :href="getHref(language)"
          class="dropdown-item"
          v-for="language in languages"
          :key="language.index"
          >{{ language[languageIndex] }}</a
        >
      </div>
    </div>
  </li>
</template>

<script>
export default {
  name: 'languageSwitcherMobile',
  data: function() {
    return {
      languageIndex,
      languages,
    };
  },
  computed: {
    selectedLanguage() {
      return this.languages.find(
        language => language.index === this.languageIndex,
      )[this.languageIndex];
    },
  },
  created: function() {
    const alternateLinks = document.querySelectorAll('[rel="alternate"]');
    alternateLinks.forEach(link => {
      this.languages.find(language => language.index === link.hreflang).href =
        link.href;
    });
  },
  methods: {
    getHref(language) {
      if (language.index !== languageIndex) {
        return language.href ? language.href : '#';
      }
      return '#';
    },
  },
};
</script>
